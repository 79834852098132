/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromLayout from '../../layout';
import * as fromAssistants from '../assistants';
import { ChatsHelper } from '../chats.helper';
import { TopBarMobileResponsive } from '../components/top-bar.mobile.responsive';
import {
  ChatActions,
  ChatModalActions,
  ChatWithMessagesActions,
} from '../store/chats.actions';
import { ChatsState, chatsFeature } from '../store/chats.reducer';
import { NewConversationComponent } from './components/new-conversation.component';

@Component({
  selector: 'squadbox-conversation',
  imports: [
    TranslateModule,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseArrowIconDirective,
    fromSeriousSystem.MessageComponent,
    fromSeriousSystem.UseLogoDirective,
    fromSeriousSystem.UseAiIconDirective,
    fromSeriousSystem.UseIllustrationDirective,
    fromSeriousSystem.DrawerComponent,
    fromSeriousSystem.OptionMenuComponent,
    fromSeriousSystem.PromptInputComponent,
    NewConversationComponent,
    TopBarMobileResponsive,
  ],
  providers: [ChatsHelper],
  template: `
    <div
      chat
      class="grid grid-rows-[min-content_auto_min-content] h-full w-full max-h-svh"
    >
      @if (isMobile()){
      <squadbox-top-bar-mobile
        [title]="
          selectedAssistant() ? selectedAssistant()?.name : selectedChat().title
        "
        [hideMoreOptionsButton]="!selectedChat().messages?.length"
        (chevronLeftButtonClicked)="toggleThreadsListingContainer()"
        (moreOptionsClicked)="isMoreOptionsOpened.set(true)"
      ></squadbox-top-bar-mobile>
      } @else {
      <div></div>
      }
      <div #messageContainer class="flex-grow overflow-y-auto">
        <div
          class="text-h5 flex tablet-landscape:pt-14 justify-center gap-2 w-full h-full"
        >
          <!-- New Conversation (without an assistant) -->
          @if ( selectedChat().messages?.length === 0 && !selectedAssistant() )
          {
          <squadbox-new-conversation
            (chatMessageAdded)="addChatMessage($event)"
          ></squadbox-new-conversation>
          } @else {

          <!-- Messages List -->
          <div
            class="
              flex flex-col gap-4
              w-full p-4 tablet-landscape:max-w-150
            "
          >
            <!-- New Conversation (with an assistant) -->
            @if (!selectedChat().messages?.length && selectedAssistant()) {
            <sd-message
              [isFromUser]="false"
              [message]="selectedAssistant()!.description"
              class="last:tablet-landscape:pb-10 last:pb-4 self-start"
            />
            } @else { @if (isLoading() && selectedChat()._count?.messages) {
            @for (message of [].constructor(selectedChat()._count?.messages);
            track $index; let i = $index) {
            <sd-message
              [isFromUser]="i % 2 !== 0 ? true : false"
              [message]="loadingMessage()"
              [isLoading]="isLoading()"
              class="last:tablet-landscape:pb-10 last:pb-4"
              [class]="{
                'self-end': i % 2 !== 0,
                'self-start': i % 2 === 0
              }"
            />
            } } @else { @for (message of selectedChat().messages; track
            message.id; let i = $index) {
            <sd-message
              [isFromUser]="message.isFromUser"
              [message]="message.content"
              class="last:tablet-landscape:pb-10 last:pb-4"
              [class]="{
                    'self-end': message.isFromUser,
                    'self-start': !message.isFromUser,
                  }"
            />
            } }
            <!-- AI IS THINKING 🧠 -->
            @if (isThinking()) {
            <sd-message
              [isFromUser]="false"
              [isThinking]="true"
              class="self-start tablet-landscape:pb-10 pb-4"
            />
            } }
          </div>
          }
        </div>
      </div>

      <div class="w-full bg-shades-white">
        <div
          class="
            flex flex-col justify-center w-full h-auto px-4 py-2
            tablet-portrait:px-6 tablet-portrait:py-4 tablet-landscape:pt-0 tablet-landscape:pb-4 tablet-landscape:px-4 tablet-landscape:items-center
          "
        >
          <!-- SCROLL BUTTON -->
          @if (showScrollButton()) {
          <button
            sdIconButton
            variant="outlined"
            color="neutral"
            size="xs"
            class="fixed m-auto place-self-center -translate-y-16 z-30 bg-shades-white"
            (click)="scrollToBottom('smooth')"
          >
            <svg sdUseArrowIcon="arrow-down"></svg>
          </button>
          }
          <!-- INPUT PROMPT -->
          <sd-prompt-input
            [disabled]="isThinking()"
            (promptSubmitted)="addChatMessage($event)"
            [hasVoiceToText]="true"
          ></sd-prompt-input>
        </div>
        <!-- DISCLAIMER -->
        <div
          class="pb-4 typo-p3 text-neutral-500 hidden tablet-landscape:block text-center"
        >
          {{ 'CHAT.AI_ASSISTANTS_CAN_MAKE_MISTAKES' | translate }}
        </div>
      </div>
    </div>

    <!-- More options -->
    @if(isMobile()) {
    <sd-drawer
      class="z-10"
      [isOpen]="isMoreOptionsOpened()"
      (closeClicked)="isMoreOptionsOpened.set(false)"
    >
      @for (option of drawerOptions(); track option.label) {
      <sd-option-menu
        [option]="option"
        (triggerAction)="onTriggerAction($event)"
      ></sd-option-menu>
      }
    </sd-drawer>
    }
  `,
  styles: [
    `
      ::-webkit-scrollbar {
        display: none;
      }
    `,
  ],
})
export class ConversationPage implements AfterViewInit {
  @ViewChild('messageContainer') messageContainer?: ElementRef;
  // Not the best thing to do, it breaks the SOLID principle
  // But we are ok to manage it instead of having a service
  // For the selectedChat and what it into the promptInput component.
  // I prefer to keep the responsibility of clear the input in the chat component
  @ViewChild(fromSeriousSystem.PromptInputComponent)
  promptInput!: fromSeriousSystem.PromptInputComponent;

  private readonly translateService = inject(TranslateService);
  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly assistantsStore =
    inject<Store<fromAssistants.AssistantsState>>(Store);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly chatsHelper = inject(ChatsHelper);

  private readonly selectedChatMessagesLength = signal(0);

  public readonly isThinking = signal(false);
  public readonly showScrollButton = signal(false);

  public readonly isThreadsListingContainerOpened =
    this.chatsStore.selectSignal(
      chatsFeature.selectIsThreadsListingContainerOpened
    );
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );
  public readonly selectedChat = computed(() => {
    const selectedChat = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    );

    return {
      ...selectedChat(),
      title: this.chatsHelper.getChatTitle(selectedChat()?.title),
    };
  });
  public readonly selectedAssistant = this.assistantsStore.selectSignal(
    fromAssistants.assistantsFeature.selectSelectedAssistant
  );

  public readonly isLoading = this.chatsStore.selectSignal(
    chatsFeature.selectIsLoading
  );
  public loadingMessage = computed(() => {
    const message = 'Lorem ipsum dolor sit amat consectetur.';
    return this.isMobile() ? message : `${message}\n${message}`;
  });

  public readonly isMoreOptionsOpened = signal(false);
  public readonly drawerOptions = signal<fromSeriousSystem.OptionMenu[]>([
    {
      label: this.translateService.instant(
        'CHAT.DROPDOWN_MENU.SAVE_ASSISTANT'
      ) as string,
      icon: 'save',
      action: 'save',
      disabled: true,
    },
    {
      label: this.translateService.instant(
        'CHAT.DROPDOWN_MENU.RENAME'
      ) as string,
      icon: 'edit',
      action: 'rename',
      disabled: true,
    },
    {
      label: this.translateService.instant(
        'CHAT.DROPDOWN_MENU.ARCHIVE'
      ) as string,
      icon: 'archive',
      action: 'archive',
      disabled: true,
    },
    {
      label: this.translateService.instant(
        'CHAT.DROPDOWN_MENU.DELETE'
      ) as string,
      icon: 'delete',
      action: 'delete',
      error: true,
    },
  ]);

  constructor() {
    let previousSelectedChat = this.selectedChat();

    effect(
      () => {
        const messagesLength = this.selectedChat().messages?.length ?? 0;

        if (this.hasMessagesLengthChanged(messagesLength)) {
          this.handleNewMessages(messagesLength);
        }

        this.updateScrollButtonVisibility();
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        if (
          this.selectedChat() &&
          this.selectedChat() !== previousSelectedChat
        ) {
          this.promptInput.clear();
        }

        previousSelectedChat = this.selectedChat();
      },
      { allowSignalWrites: true }
    );
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    this.messageContainer?.nativeElement.addEventListener('scroll', () => {
      this.showScrollButton.set(
        this.messageContainer?.nativeElement.scrollTop <
          this.messageContainer?.nativeElement.scrollHeight -
            this.messageContainer?.nativeElement.clientHeight
      );
    });
  }

  public scrollToBottom(behavior: 'smooth' | 'auto' = 'auto'): void {
    try {
      this.messageContainer?.nativeElement.scrollTo({
        top: this.messageContainer?.nativeElement.scrollHeight,
        behavior,
      });
    } catch (err) {
      console.error('Could not scroll to bottom:', err);
    }
  }

  public addChatMessage(content: string): void {
    const selectedAssistant = this.selectedAssistant();

    this.isThinking.set(true);

    this.chatsStore.dispatch(
      ChatWithMessagesActions.addChatMessage(
        selectedAssistant
          ? {
              content,
              assistant: selectedAssistant,
            }
          : { content }
      )
    );
  }

  private hasMessagesLengthChanged(messagesLength: number): boolean {
    return messagesLength !== this.selectedChatMessagesLength();
  }

  private handleNewMessages(messagesLength: number): void {
    const isLastMessageFromChat =
      !this.selectedChat().messages?.[messagesLength - 1]?.isFromUser;

    if (isLastMessageFromChat) {
      this.isThinking.set(false);
    }

    this.selectedChatMessagesLength.set(messagesLength);

    setTimeout(() => {
      this.scrollToBottom();
      this.updateScrollButtonVisibility();
    }, 0);
  }

  private updateScrollButtonVisibility(): void {
    this.showScrollButton.set(
      this.messageContainer?.nativeElement.scrollTop <
        this.messageContainer?.nativeElement.scrollHeight -
          this.messageContainer?.nativeElement.clientHeight
    );
  }

  public toggleThreadsListingContainer() {
    if (this.isThreadsListingContainerOpened()) {
      this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
    } else {
      this.chatsStore.dispatch(ChatActions.openThreadsListingContainer());
    }
  }

  public onTriggerAction(action: string) {
    if (action === 'delete') {
      this.chatsStore.dispatch(ChatModalActions.openDeleteChatModal());
    }
  }
}
