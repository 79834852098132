import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromGenerated from '../../_generated';

@Component({
  selector: 'squadbox-threads-listing-collapsed-fabs-desktop',
  imports: [
    NgClass,
    RouterModule,
    TranslateModule,
    fromSeriousSystem.UseNavigationIconDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.TooltipDirective,
  ],
  template: `
    <!-- Threads Listing / Collapsed (FABs) -->
    <div
      class="
        fixed left-20 top-4 flex flex-col gap-4 items-center transform transition-transform duration-300
      "
      [ngClass]="{
        '-translate-x-full hidden': isThreadsListingContainerOpened(),
        'translate-x-0': !isThreadsListingContainerOpened()
      }"
    >
      <div
        sdIconButton
        variant="outlined"
        color="neutral"
        size="md"
        [ngClass]="{
          'opacity-0': isThreadsListingContainerOpened(),
          'opacity-100 ': !isThreadsListingContainerOpened()
        }"
        (click)="toggleThreadsListingContainerClicked.emit()"
      >
        <svg sdUseNavigationIcon="sidebar"></svg>
      </div>
      <button
        sdIconButton
        variant="outlined"
        color="primary"
        size="md"
        [@moveIcon]="isThreadsListingContainerOpened() ? 'open' : 'closed'"
        (click)="newConvoClicked.emit()"
        routerLink="/chats/conversation"
      >
        <svg sdUseNavigationIcon="new-chat"></svg>
      </button>

      @for (assistant of assistants(); track assistant.uuid) {
      <button
        [@fadeIn]="!isThreadsListingContainerOpened()"
        class="
          flex items-center justify-center
          size-10 rounded-full
          outline outline-primary-500/15 hover:outline-primary-500/25 outline-offset-4
          bg-primary-500/5 hover:bg-primary-500/10
        "
        routerLink="/chats/conversation/{{ assistant.uuid }}"
        [sdTooltip]="assistant.name"
        sdTooltipDirection="right"
        (click)="newConvoClicked.emit()"
      >
        <img class="size-5" src="{{ assistant.logo }}" />
      </button>
      }

      <button
        [@fadeIn]="!isThreadsListingContainerOpened()"
        sdIconButton
        variant="outlined"
        color="neutral"
        size="md"
        [ngClass]="{
          'opacity-0': isThreadsListingContainerOpened(),
          'opacity-100 ': !isThreadsListingContainerOpened()
        }"
        routerLink="/chats/assistants"
        sdTooltip="{{ 'MAIN_NAVIGATION_ITEMS.ASSISTANTS' | translate }}"
        sdTooltipDirection="right"
      >
        <svg sdUseNavigationIcon="dashboard"></svg>
      </button>
    </div>
  `,
  animations: [
    trigger('moveIcon', [
      // eslint-disable-next-line sonarjs/no-duplicate-string
      state('open', style({ transform: 'translate(0, 0)' })),
      state('closed', style({ transform: 'translate(0, 0)' })),
      transition('open => closed', [
        animate(
          '300ms',
          keyframes([
            style({ transform: 'translate(11rem, -4rem)', offset: 0 }),
            style({ transform: 'translate(9.9rem, -3.6rem)', offset: 0.1 }),
            style({ transform: 'translate(8.8rem, -3.2rem)', offset: 0.2 }),
            style({ transform: 'translate(7.7rem, -2.8rem)', offset: 0.3 }),
            style({ transform: 'translate(6.6rem, -2.4rem)', offset: 0.4 }),
            style({ transform: 'translate(5.5rem, -2rem)', offset: 0.5 }),
            style({ transform: 'translate(4.4rem, -1.6rem)', offset: 0.6 }),
            style({ transform: 'translate(3.3rem, -1.2rem)', offset: 0.7 }),
            style({ transform: 'translate(2.2rem, -0.8rem)', offset: 0.8 }),
            style({ transform: 'translate(1.1rem, -0.4rem)', offset: 0.9 }),
            style({ transform: 'translate(0, 0)', offset: 1 }),
          ])
        ),
      ]),
    ]),
    trigger('fadeIn', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('200ms 250ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
  styles: [
    `
      :host {
        @apply absolute left-20 top-4 z-20;
      }
    `,
  ],
})
export class ThreadsListingCollapsedFabsDesktopResponsive {
  public readonly assistants = input.required<fromGenerated.AssistantView[]>();
  public readonly isThreadsListingContainerOpened = input.required<boolean>();

  public readonly newConvoClicked = output();
  public readonly toggleThreadsListingContainerClicked = output();
}
