import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import { FileActions } from './files.actions';

@Injectable()
export class FilesEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly fileUploadService = inject(fromGenerated.FileUploadService);

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileActions.uploadFile),
      mergeMap(({ transactionId, id, file }) =>
        this.fileUploadService
          .fileUploadControllerUploadFile(id, transactionId, file)
          .pipe(
            map(() =>
              FileActions.uploadFileSuccess({
                id,
              })
            ),
            catchError((error: Error) =>
              of(
                FileActions.uploadFileFailure({
                  id,
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );
}
