import { Component, computed, inject, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromAssistants from './assistants';
import { ChatsHelper } from './chats.helper';
import { ConversationTitleFabDesktopResponsive } from './components/conversation-title-fab.desktop.responsive';
import { ThreadsListingCollapsedFabsDesktopResponsive } from './components/threads-listing-collapsed-fabs.desktop.responsive';
import { ThreadsListingDesktopResponsive } from './components/threads-listing.desktop.responsive';
import { ChatActions } from './store/chats.actions';
import { ChatsState, chatsFeature } from './store/chats.reducer';

@Component({
  selector: 'squadbox-chats-desktop',
  imports: [
    RouterModule,
    TranslateModule,
    ThreadsListingCollapsedFabsDesktopResponsive,
    ThreadsListingDesktopResponsive,
    ConversationTitleFabDesktopResponsive,
  ],
  providers: [ChatsHelper],
  template: `
    @if (!isThreadsListingContainerOpened() && ((selectedChat().messages?.length
    && selectedChat().title) || selectedAssistant())) {
    <squadbox-conversation-title-fab-desktop
      [title]="
        selectedAssistant() ? selectedAssistant()?.name : selectedChat().title
      "
      [enableDropdown]="!selectedAssistant()"
    ></squadbox-conversation-title-fab-desktop>
    }

    <div
      class="grid grid-flow-col grid-cols-[min-content_auto] h-full overflow-hidden"
    >
      <div class="relative">
        <squadbox-threads-listing-collapsed-fabs-desktop
          [isThreadsListingContainerOpened]="isThreadsListingContainerOpened()"
          [assistants]="activeAssistants()"
          (newConvoClicked)="newConvoClicked.emit()"
          (toggleThreadsListingContainerClicked)="
            toggleThreadsListingContainer()
          "
        ></squadbox-threads-listing-collapsed-fabs-desktop>

        <squadbox-threads-listing-desktop
          [isThreadsListingContainerOpened]="isThreadsListingContainerOpened()"
          [assistants]="activeAssistants()"
          [itemNavigations]="itemNavigations()"
          (newConvoClicked)="newConvoClicked.emit()"
          (toggleThreadsListingContainerClicked)="
            toggleThreadsListingContainer()
          "
          (chatDeleteClicked)="chatDeleteClicked.emit()"
          (itemClicked)="itemClicked.emit($event)"
        >
        </squadbox-threads-listing-desktop>
      </div>
      <div class="h-full overflow-hidden">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styles: [``],
})
export class ChatsDesktopResponsive {
  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly assistantsStore = inject<Store<ChatsState>>(Store);

  public readonly translateService = inject(TranslateService);

  private readonly chatsHelper = inject(ChatsHelper);

  public readonly isNewChat = input<boolean>(false);
  public readonly itemNavigations =
    input.required<fromSeriousSystem.ItemNavigation[]>();

  public readonly newConvoClicked = output();
  public readonly chatDeleteClicked = output();
  public readonly itemClicked = output<fromSeriousSystem.ItemNavigation>();

  public readonly selectedChat = computed(() => {
    const selectedChat = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    );

    return {
      ...selectedChat(),
      title: this.chatsHelper.getChatTitle(selectedChat()?.title),
    };
  });

  public readonly selectedAssistant = this.assistantsStore.selectSignal(
    fromAssistants.assistantsFeature.selectSelectedAssistant
  );
  public readonly isThreadsListingContainerOpened =
    this.chatsStore.selectSignal(
      chatsFeature.selectIsThreadsListingContainerOpened
    );
  public readonly activeAssistants = this.assistantsStore.selectSignal(
    fromAssistants.assistantsFeature.selectActiveAssistants
  );

  public toggleThreadsListingContainer() {
    if (this.isThreadsListingContainerOpened()) {
      this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
    } else {
      this.chatsStore.dispatch(ChatActions.openThreadsListingContainer());
    }
  }
}
